<template>
  <Modal v-if="isOpen" class="DialogErrorsHistory" @close="isOpen = false">
    <template #header>
      <div class="Financial__modalHeader">
        Erros no arquivo: <strong>{{ spreadSheetTitle }}</strong>
      </div>
    </template>

    <template #body>
      <DataTable
        :header="headers"
        :table="content"
        :max-size="false"
      />
    </template>
  </Modal>
</template>

<script>
import { Modal } from '@/components/molecules'
import { DataTable } from '@/components/molecules'

export default {
  name: 'DialogErrors',

  components: {
    Modal,
    DataTable,
  },

  data() {
    return {
      isOpen: false,
      headers: {},
      content: [],
      title: ''
    }
  },

  computed: {
    spreadSheetTitle() {
      if(this.title.length > 40) return this.title.substring(0, 40) + '...'
      return this.title
    }
  },

  methods: {
    open(headers, content, title) {
      this.isOpen = true
      this.headers = headers
      this.content = content
      this.title = title
    }
  }
}
</script>

<style lang="scss">
  .DialogErrorsHistory {
    .Modal__container {
      width: 600px;
      height: fit-content;
      padding-bottom: 20px;

      @media #{$mobile-view} {
        width: 100%;
      }
    }

    .Financial__modalHeader {
      padding-top: 6px;
      padding-left: 10px;
    }

    .FinancialUpload__disclaimer {
      padding: 25px 0;
      text-align: center;
    }

    .Modal__buttons {
      display: flex;
      justify-content: space-evenly;
    }

    .Modal__button {
      width: 40% !important;
    }
  }
</style>
